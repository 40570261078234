<template>
  <b-tabs v-if="theOptions" content-class="">
    <b-tab
      v-for="(option, index) in theOptions"
      :key="index"
      @click="
        filter(option.itemToken);
        currentTab = option.currentTab;
      "
      :title-item-class="'custom-tab-outer-li'"
      :active="currentTab == option.currentTab ? true : false"
    >
      <template #title>
        <span v-b-popover.hover.bottomright="`${option.itemName}`">
          <img :src="`${option.itemImagePath}`" class="icon-tab" />
          <span>{{ option.itemName }}</span>
          <span class="badge badge-dark" style="margin-inline-start: 8px">
            {{ option.count }}
          </span>
        </span>
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
// import imgAll from "@/assets/images/JoiningApplicationEducationals.svg";
import imgAccepted from "@/assets/images/approval.svg";
import imgApproval from "@/assets/images/accepted.svg";
import imgRejected from "@/assets/images/rejected.svg";
import imgCanceled from "@/assets/images/cancel.svg";
import { EDUCATIONAl_TYPE } from "./../../../../utils/constantLists";

export default {
  name: "JoiningApplicationEducationalTabs",
  props: {
    joiningApplicationEducationalsStatistics: {
      type: Object,
    },
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      currentTab: "",
      filterData: this.theFilterData,
    };
  },
  computed: {
    totalCountIsNotRelatedWithGroup() {
      return this.joiningApplicationEducationalsStatistics
        .totalCountIsNotRelatedWithGroup;
    },
    totalCountIsRelatedWithGroup() {
      return this.joiningApplicationEducationalsStatistics
        .totalCountIsRelatedWithGroup;
    },
    totalCountIsFreezed() {
      return this.joiningApplicationEducationalsStatistics.totalCountIsFreezed;
    },
    totalCountIsPostponed() {
      return this.joiningApplicationEducationalsStatistics
        .totalCountIsPostponed;
    },
    allCount() {
      return this.joiningApplicationEducationalsStatistics
        .totalJoiningApplication;
    },

    theOptions() {
      let tabOptions = [];

      // tabOptions.push({
      //   itemToken: "",
      //   itemName: this.$t("JoiningApplicationEducationals.tabs.all"),
      //   itemImagePath: imgAll,
      //   currentTab: "all",
      //   count: this.allCount,
      // });

      tabOptions.push({
        itemToken: EDUCATIONAl_TYPE.NotRelatedWithGroup,
        itemName: this.$t("JoiningApplicationEducationals.tabs.notRelated"),
        itemImagePath: imgAccepted,
        currentTab: "notRelated",
        count: this.totalCountIsNotRelatedWithGroup,
      });

      tabOptions.push({
        itemToken: EDUCATIONAl_TYPE.IsFreezed,
        itemName: this.$t("JoiningApplicationEducationals.tabs.freezed"),
        itemImagePath: imgRejected,
        currentTab: "freezed",
        count: this.totalCountIsFreezed,
      });

      tabOptions.push({
        itemToken: EDUCATIONAl_TYPE.IsPostponed,
        itemName: this.$t("JoiningApplicationEducationals.tabs.postponed"),
        itemImagePath: imgCanceled,
        currentTab: "postponed",
        count: this.totalCountIsPostponed,
      });

      tabOptions.push({
        itemToken: EDUCATIONAl_TYPE.RelatedWithGroup,
        itemName: this.$t("JoiningApplicationEducationals.tabs.related"),
        itemImagePath: imgApproval,
        currentTab: "related",
        count: this.totalCountIsRelatedWithGroup,
      });

      return tabOptions;
    },
  },
  methods: {
    filter(educationalType) {
      if (this.filterData.educationalType != educationalType) {
        this.filterData.educationalType = educationalType;
        this.$emit("search", this.filterData);
      }
    },
    setCurrentTab() {
      this.currentTab = "notRelated";
      this.filter(EDUCATIONAl_TYPE.NotRelatedWithGroup);
      // if (!this.currentTab) {
      //   this.currentTab = "notRelated";
      //   this.filter(EDUCATIONAl_TYPE.NotRelatedWithGroup);
      // } else {
      //   let currentitem = this.tabOptions.filter(
      //     (i) => i.currentTab == this.currentTab
      //   );
      //   this.filter(currentitem[0].itemToken);
      // }
    },
    currentTabUpdated() {
      this.$emit("currentTabUpdated", this.currentTab);
    },
  },
  watch: {
    currentTab() {
      this.currentTabUpdated();
    },
  },
  async created() {
    this.setCurrentTab();
  },
};
</script>
