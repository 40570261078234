import BaseEntityModelFilter from "./../../general/BaseEntityModelFilter";
import { APPROVAL_TYPE_TOKENS } from "./../../../utils/constantLists";

export default class JoiningApplicationEducationalsFilter extends BaseEntityModelFilter {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setFilterInitialValue();
    this.activationTypeTokens = "AST-17400"; //for test
    this.educationalType = "";
    this.approvalTypeToken = APPROVAL_TYPE_TOKENS.Accepted;
    this.userStudentToken = "";
    this.educationalGroupToken = "";
    this.systemComponentHierarchyToken = "";
    this.systemComponentToken = "";
    this.educationalCategoryToken = ""; //searise
    this.educationalJoiningApplicationToken = ""; //
    this.freezedDateTimeFrom = "";
    this.freezedDateTimeTo = "";
    this.postponedDateTimeFrom = "";
    this.postponedDateTimeTo = "";
    this.joiningDateTimeFrom = "";
    this.joiningDateTimeTo = "";
    this.sendTo = "";
  }
  fillData(data) {
    if (data) {
      this.fillFilterData(data);
      this.educationalType = data.educationalType ?? "";
      this.approvalTypeToken = data.approvalTypeToken ?? "";
      this.userStudentToken = data.userStudentToken ?? "";
      this.educationalGroupToken = data.educationalGroupToken ?? "";
      this.systemComponentHierarchyToken =
        data.systemComponentHierarchyToken ?? "";
      this.systemComponentToken = data.systemComponentToken ?? "";
      this.educationalCategoryToken = data.educationalCategoryToken ?? "";
      this.educationalJoiningApplicationToken =
        data.educationalJoiningApplicationToken ?? "";
      this.freezedDateTimeFrom = data.freezedDateTimeFrom ?? "";
      this.freezedDateTimeTo = data.freezedDateTimeTo ?? "";
      this.postponedDateTimeFrom = data.postponedDateTimeFrom ?? "";
      this.postponedDateTimeTo = data.postponedDateTimeTo ?? "";
      this.joiningDateTimeFrom = data.joiningDateTimeFrom ?? "";
      this.joiningDateTimeTo = data.joiningDateTimeTo ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
