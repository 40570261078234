<template>
  <b-modal
    id="JoiningApplicationEducationalChangePostponedStatus"
    scrollable
    centered
    hide-footer
  >
    <!-- @shown="shown" -->
    <PreLoader v-if="isLoading" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/type.svg" class="icon-lg" />
        {{ $t("JoiningApplicationEducationals.changePostponedStatus") }}
      </h3>
    </template>

    <h4 class="text-center">
      (
      <span class="co-red">
        {{
          joiningApplicationEducational.educationalCategoryInfoData
            .educationalCategoryNameCurrent
        }}
      </span>
      )
    </h4>

    <div class="row">
      <CustomSelectBox
        :className="'col-12'"
        :id="'joiningApplicationEducational-postponedStatusToken'"
        :value="joiningApplicationEducational.postponedTypeToken"
        :options="postponedTypesTokenOptions"
        v-on:changeValue="
          joiningApplicationEducational.postponedTypeToken = $event
        "
        :title="$t('general.state')"
        :imgName="'type.svg'"
        :imgStatus="true"
      />
      <template v-if="currentTab == 'notRelated'">
        <DateTimePicker
          class="col-md-12"
          :id="'joiningApplicationEducational-postponedToDateTime'"
          type="dateTime"
          :value="joiningApplicationEducational.postponedToDateTime"
          :title="$t('JoiningApplicationEducationals.postponedTo')"
          v-on:changeValue="
            joiningApplicationEducational.postponedToDateTime = $event.dateTime
          "
          :language="language"
        />

        <TextArea
          :className="'col-md-12'"
          :id="`joiningApplicationEducational-postponedNotes`"
          :value="joiningApplicationEducational.postponedNotes"
          v-on:changeValue="
            joiningApplicationEducational.postponedNotes = $event
          "
          :title="$t('notes')"
          :imgName="'notes.svg'"
        />
      </template>
    </div>

    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="changePostponedStatus"
      >
        {{ $t("ok") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="
          $bvModal.hide('JoiningApplicationEducationalChangePostponedStatus')
        "
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import DateTimePicker from "./../../../../components/general/DateTimePicker.vue";
import TextArea from "./../../../../components/general/TextArea.vue";
import { STATUS } from "./../../../../utils/constants";
import { POSTPONED_TYPE } from "./../../../../utils/constantLists";
import { getLanguage, objectToFormData } from "./../../../../utils/functions";
import { getDialogOfPostponedTypes } from "./../../../../utils/dialogsOfConstantsLists";
import apiJoiningApplicationEducational from "./../../../../api/educational/joiningApplicationEducationals";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    DateTimePicker,
    TextArea,
  },
  data() {
    return {
      postponedTypesTokenOptionsList: getDialogOfPostponedTypes(),
      POSTPONED_TYPE,
      language: getLanguage(),
    };
  },
  props: ["joiningApplicationEducational", "currentTab"],
  computed: {
    postponedTypesTokenOptions: function () {
      let arr = [];
      if (this.currentTab == "notRelated") {
        let obj = this.postponedTypesTokenOptionsList.find(
          (e) => e.value === POSTPONED_TYPE.Delayed
        );
        if (obj) arr.push(obj);
      }
      if (this.currentTab == "postponed") {
        let obj = this.postponedTypesTokenOptionsList.find(
          (e) => e.value === POSTPONED_TYPE.NotPostponed
        );
        if (obj) arr.push(obj);
      }

      return arr;
    },
  },
  methods: {
    async changePostponedStatus() {
      this.isLoading = true;
      try {
        let model = {
          joiningApplicationEducationalToken:
            this.joiningApplicationEducational
              .joiningApplicationEducationalToken,
          postponedStatusToken:
            this.joiningApplicationEducational.postponedTypeToken,
          postponedToDateTime:
            this.joiningApplicationEducational.postponedToDateTime,
          postponedNotes: this.joiningApplicationEducational.postponedNotes,
        };
        let formData = objectToFormData(model);
        const response =
          await apiJoiningApplicationEducational.changePostponedStatus(
            formData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide(
            "JoiningApplicationEducationalChangePostponedStatus"
          );
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg, false);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>
