import defaultImg from "@/assets/images/EducationalJoiningApplications.svg";
import EducationalCategoryInfoData from "./../../countedServices/EducationalCategoryInfoData";
import EducationalGroupJoining from "./EducationalGroupJoining";
import EducationalJoiningApplicationInfoData from "./EducationalJoiningApplicationInfoData";

export default class EducationalJoiningApplication {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.joiningApplicationEducationalToken = "";
    this.educationalCategoryToken = "";
    this.educationalGroupStudentToken = "";
    this.educationalJoiningApplicationToken = "";
    this.postponedTypeToken = "";
    this.postponedTypeNameCurrent = "";
    this.postponedNotes = "";
    this.postponedToDateTime = "";
    this.postponedToDateTimeCustomized = "";
    this.postponedToDate = "";
    this.postponedToTime = "";
    this.isHaveChildrenStatus = "";
    this.educationalCategoryInfoData = new EducationalCategoryInfoData();
    this.educationalGroupJoining = new EducationalGroupJoining();
    this.educationalJoiningApplicationInfoData =
      new EducationalJoiningApplicationInfoData();
  }
  fillData(data) {
    if (data) {
      this.joiningApplicationEducationalToken =
        data.joiningApplicationEducationalToken ?? "";
      this.educationalCategoryToken = data.educationalCategoryToken ?? "";
      this.educationalGroupStudentToken =
        data.educationalGroupStudentToken ?? "";
      this.educationalJoiningApplicationToken =
        data.educationalJoiningApplicationToken ?? "";
      this.postponedTypeToken = data.postponedTypeToken ?? "";
      this.postponedTypeNameCurrent = data.postponedTypeNameCurrent ?? "";
      this.postponedNotes = data.postponedNotes ?? "";
      this.postponedToDateTime = data.postponedToDateTime ?? "";
      this.postponedToDateTimeCustomized =
        data.postponedToDateTimeCustomized ?? "";
      this.postponedToDate = data.postponedToDate ?? "";
      this.postponedToTime = data.postponedToTime ?? "";
      this.isHaveChildrenStatus = data.isHaveChildrenStatus ?? "";
      this.educationalCategoryInfoData.fillData(
        data.educationalCategoryInfoData
      );
      this.educationalGroupJoining.fillData(data.educationalGroupJoining);
      this.educationalJoiningApplicationInfoData.fillEducationalJoiningApplicationInfoData(
        data.educationalJoiningApplicationInfoData
      );
    } else {
      this.setInitialValue();
    }
  }
}
