<template>
  <CustomBottomSheet
    :refName="'filterSheet'"
    size="lg"
    :headerText="$t('filter')"
    :headerIcon="icon"
  >
    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`userStudentToken`"
        :value="filterData.userStudentToken"
        :options="userTokenOptions"
        v-on:changeValue="
          filterData.userStudentToken = $event;
          getEducationalJoiningApplicationsDialog();
        "
        :title="$t('students.select')"
        :imgName="'students.svg'"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`educationalJoiningApplicationToken`"
        :value="filterData.educationalJoiningApplicationToken"
        :options="educationalJoiningApplicationTokenOptions"
        v-on:changeValue="
          filterData.educationalJoiningApplicationToken = $event
        "
        :title="$t('EducationalJoiningApplications.select')"
        :imgName="'EducationalJoiningApplications.svg'"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`filter-educationalGroupToken`"
        :value="filterData.educationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="filterData.educationalGroupToken = $event"
        :title="$t('EducationalGroups.select')"
        :imgName="'EducationalGroups.svg'"
      />
      <BaseTree
        :hierarchySystemTypeFilterToken="hierarchySystemTypeFilterToken"
        :treeModel="getTreeModel"
        v-on:changeValue="baseTreeChanged($event)"
        :showParent="true"
        :filterParentDirect="true"
      />

      <template v-if="currentTab != 'postponed'">
        <DateTimePicker
          class="col-md-6"
          :id="`filter-freezedDateTimeFrom`"
          type="dateTime"
          :value="filterData.freezedDateTimeFrom"
          :title="$t('JoiningApplicationEducationals.freezedFrom')"
          v-on:changeValue="filterData.freezedDateTimeFrom = $event.dateTime"
          :language="language"
        />
        <DateTimePicker
          class="col-md-6"
          :id="`filter-freezedDateTimeTo`"
          type="dateTime"
          :value="filterData.freezedDateTimeTo"
          :title="$t('JoiningApplicationEducationals.freezedTo')"
          v-on:changeValue="filterData.freezedDateTimeTo = $event.dateTime"
          :language="language"
        />
      </template>
      <template v-if="currentTab != 'freezed'">
        <DateTimePicker
          class="col-md-6"
          :id="`filter-postponedDateTimeFrom`"
          type="dateTime"
          :value="filterData.postponedDateTimeFrom"
          :title="$t('JoiningApplicationEducationals.postponedFrom')"
          v-on:changeValue="filterData.postponedDateTimeFrom = $event.dateTime"
          :language="language"
        />
        <DateTimePicker
          class="col-md-6"
          :id="`filter-postponedDateTimeTo`"
          type="dateTime"
          :value="filterData.postponedDateTimeTo"
          :title="$t('JoiningApplicationEducationals.postponedTo')"
          v-on:changeValue="filterData.postponedDateTimeTo = $event.dateTime"
          :language="language"
        />
      </template>

      <DateTimePicker
        class="col-md-6"
        :id="`filter-joiningDateTimeFrom`"
        type="dateTime"
        :value="filterData.joiningDateTimeFrom"
        :title="$t('JoiningApplicationEducationals.joiningFrom')"
        v-on:changeValue="filterData.joiningDateTimeFrom = $event.dateTime"
        :language="language"
      />
      <DateTimePicker
        class="col-md-6"
        :id="`filter-joiningDateTimeTo`"
        type="dateTime"
        :value="filterData.joiningDateTimeTo"
        :title="$t('JoiningApplicationEducationals.joiningTo')"
        v-on:changeValue="filterData.joiningDateTimeTo = $event.dateTime"
        :language="language"
      />
    </div>

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="search">
        <img src="@/assets/images/search-icon.svg" :title="$t('search')" />
      </div>
      <div @click.prevent="closeBottomSheet('filterSheet')" class="icon-cancel">
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import BaseTree from "./../../../../components/general/BaseTree.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import DateTimePicker from "./../../../../components/general/DateTimePicker.vue";
import { getEducationalGroupsDialog } from "./../../../../utils/dialogsOfApi";
import { getLanguage } from "./../../../../utils/functions";
import generalMixin from "./../../../../utils/generalMixin";
import { USER_TYPE, SYSTEM_TYPE } from "./../../../../utils/constantLists";
import {
  getUsersDialog,
  getEducationalJoiningApplicationsDialog,
} from "./../../../../utils/dialogsOfApi";
import icon from "@/assets/images/filter.svg";

export default {
  mixins: [generalMixin],
  props: {
    theFilterData: {
      type: Object,
    },
    currentTab: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filterData: this.theFilterData,
      language: getLanguage(),
      userTokenOptions: [],
      educationalJoiningApplicationTokenOptions: [],
      educationalGroupTokenOptions: [],
      icon,
    };
  },
  components: {
    CustomBottomSheet,
    BaseTree,
    CustomSelectBox,
    DateTimePicker,
  },
  methods: {
    baseTreeChanged(data) {
      if (data.systemTypeToken == SYSTEM_TYPE.EducationalActivity) {
        this.filterData.systemComponentHierarchyToken = data.hierarchyToken;
        this.filterData.systemComponentToken = data.componentToken;
        this.filterData.educationalCategoryToken = data.parentToken;
      }
    },
    async getStudentsDialog() {
      this.isLoading = true;
      let params = {
        modelName: "students",
        userTypeToken: USER_TYPE.Student,
      };
      this.userTokenOptions = await getUsersDialog(params);
      this.isLoading = false;
    },
    async getEducationalJoiningApplicationsDialog() {
      this.isLoading = true;
      let params = {
        userStudentToken: this.filterData.userStudentToken,
      };
      this.educationalJoiningApplicationTokenOptions =
        await getEducationalJoiningApplicationsDialog(params);
      this.isLoading = false;
    },
    search() {
      this.closeBottomSheet("filterSheet");
      this.$emit("search", this.filterData);
    },
    async getEducationalGroupsDialog() {
      this.isLoading = true;
      this.educationalGroupTokenOptions = await getEducationalGroupsDialog();
      this.isLoading = false;
    },
  },
  created() {
    this.getStudentsDialog();
    this.getEducationalGroupsDialog();
    this.getEducationalJoiningApplicationsDialog();
  },
  computed: {
    hierarchySystemTypeFilterToken() {
      return SYSTEM_TYPE.EducationalActivity;
    },
    getTreeModel() {
      let treeModelObject = {
        systemComponentHierarchyToken:
          this.filterData.systemComponentHierarchyToken,
        hierarchyTitle: this.$t(
          "SystemComponentsHierarchies.selectEducationalActivity"
        ),
        systemComponentToken: this.filterData.systemComponentToken,
        componentTitle: this.$t("SystemComponents.selectEducationalActivity"),
        parentToken: this.filterData.educationalCategoryToken,
        parentTitle: this.$t("EducationalCategories.selectParent"),
        parentImgName: "EducationalCategories.svg",
      };
      return treeModelObject;
    },
  },
};
</script>
