<template>
  <div v-if="joiningApplicationEducationalsData" class="table-container">
    <template v-if="currentTab == 'notRelated'">
      <table class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th colspan="4">{{ $t("EducationalCategories.data") }}</th>
            <th colspan="2">{{ $t("students.data") }}</th>
            <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
          </tr>
          <tr>
            <th>{{ $t("general.image") }}</th>
            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
            <th>{{ $t("EducationalJoiningApplications.code") }}</th>

            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              joiningApplicationEducational, index
            ) in joiningApplicationEducationalsData"
            :key="index"
          >
            <td>{{ ++index + filterData.currentIndex }}</td>
            <td>
              <img
                class="item-img-table"
                :src="
                  fullPathFileFromServer(
                    joiningApplicationEducational.educationalCategoryInfoData
                      .educationalCategoryImagePath,
                    defaultImg
                  )
                "
                :onerror="`this.src='${defaultImg}'`"
              />
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalCategoryInfoData
                    .fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalCategoryInfoData
                    .educationalCategoryNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.userStudentInfoData
                    .fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.userStudentInfoData
                    .userNameCurrent
                )
              }}
            </td>
            <td class="fmenu-item-container">
              <FloatingMenu>
                <li v-if="checkPrivilege(hasEducationalGroupStudentAdd())">
                  <button
                    v-b-modal.JoiningApplicationEducationalAddToGroup
                    :title="$t('EducationalGroupStudents.add')"
                    @click="
                      setJoiningApplicationEducationalData(
                        joiningApplicationEducational
                      )
                    "
                  >
                    <img src="@/assets/images/plus.svg" />
                  </button>
                </li>
                <li
                  v-if="
                    checkPrivilege(
                      hasEducationalJoiningApplicationChangePostponedStatus()
                    )
                  "
                >
                  <button
                    v-b-modal.JoiningApplicationEducationalChangePostponedStatus
                    :title="
                      $t('JoiningApplicationEducationals.changePostponedStatus')
                    "
                    @click="
                      setJoiningApplicationEducationalData(
                        joiningApplicationEducational
                      )
                    "
                  >
                    <img src="@/assets/images/type.svg" />
                  </button>
                </li>
              </FloatingMenu>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-if="currentTab == 'related'">
      <table class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th colspan="4">{{ $t("EducationalCategories.data") }}</th>
            <th colspan="2">{{ $t("students.data") }}</th>
            <th colspan="3">{{ $t("EducationalGroups.data") }}</th>
            <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
          </tr>
          <tr>
            <th>{{ $t("general.image") }}</th>
            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
            <th>{{ $t("EducationalJoiningApplications.code") }}</th>

            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>

            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
            <th>{{ $t("UserQualifications.joiningDateTime") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              joiningApplicationEducational, index
            ) in joiningApplicationEducationalsData"
            :key="index"
          >
            <td>{{ ++index + filterData.currentIndex }}</td>
            <td>
              <img
                class="item-img-table"
                :src="
                  fullPathFileFromServer(
                    joiningApplicationEducational.educationalCategoryInfoData
                      .educationalCategoryImagePath,
                    defaultImg
                  )
                "
                :onerror="`this.src='${defaultImg}'`"
              />
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalCategoryInfoData
                    .fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalCategoryInfoData
                    .educationalCategoryNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.userStudentInfoData
                    .fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.userStudentInfoData
                    .userNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalGroupJoining
                    .educationalGroupInfo.fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalGroupJoining
                    .educationalGroupInfo.educationalGroupNameCurrent
                )
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  joiningApplicationEducational.educationalGroupJoining
                    .placementDate,
                  joiningApplicationEducational.educationalGroupJoining
                    .placementTime
                )
              }}
            </td>
            <td class="fmenu-item-container">
              <FloatingMenu>
                <li v-if="checkPrivilege(hasChangeStudentFreezeStatus())">
                  <button
                    v-b-modal.EducationalGroupStudentFreeze
                    @click="
                      setJoiningApplicationEducationalData(
                        joiningApplicationEducational
                      )
                    "
                    :title="$t('general.makeFreezed')"
                  >
                    <img src="@/assets/images/user-cancel.svg" />
                  </button>
                </li>
              </FloatingMenu>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-if="currentTab == 'postponed'">
      <table class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th colspan="4">{{ $t("EducationalCategories.data") }}</th>
            <th colspan="2">{{ $t("students.data") }}</th>
            <th rowspan="2">
              {{ $t("JoiningApplicationEducationals.postponedTo") }}
            </th>
            <th rowspan="2">
              {{ $t("JoiningApplicationEducationals.postponedReason") }}
            </th>
            <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
          </tr>
          <tr>
            <th>{{ $t("general.image") }}</th>
            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
            <th>{{ $t("EducationalJoiningApplications.code") }}</th>

            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              joiningApplicationEducational, index
            ) in joiningApplicationEducationalsData"
            :key="index"
          >
            <td>{{ ++index + filterData.currentIndex }}</td>
            <td>
              <img
                class="item-img-table"
                :src="
                  fullPathFileFromServer(
                    joiningApplicationEducational.educationalCategoryInfoData
                      .educationalCategoryImagePath,
                    defaultImg
                  )
                "
                :onerror="`this.src='${defaultImg}'`"
              />
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalCategoryInfoData
                    .fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalCategoryInfoData
                    .educationalCategoryNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.userStudentInfoData
                    .fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.userStudentInfoData
                    .userNameCurrent
                )
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  joiningApplicationEducational.postponedToDate,
                  joiningApplicationEducational.postponedToTime
                )
              }}
            </td>

            <td>
              {{ isDataExist(joiningApplicationEducational.postponedNotes) }}
            </td>

            <td class="fmenu-item-container">
              <FloatingMenu>
                <li v-if="checkPrivilege(hasEducationalGroupStudentAdd())">
                  <button
                    v-b-modal.JoiningApplicationEducationalAddToGroup
                    :title="$t('EducationalGroupStudents.add')"
                    @click="
                      setJoiningApplicationEducationalData(
                        joiningApplicationEducational
                      )
                    "
                  >
                    <img src="@/assets/images/plus.svg" />
                  </button>
                </li>
                <li
                  v-if="
                    checkPrivilege(
                      hasEducationalJoiningApplicationChangePostponedStatus()
                    )
                  "
                >
                  <button
                    v-b-modal.JoiningApplicationEducationalChangePostponedStatus
                    :title="
                      $t('JoiningApplicationEducationals.changePostponedStatus')
                    "
                    @click="
                      setJoiningApplicationEducationalData(
                        joiningApplicationEducational
                      )
                    "
                  >
                    <img src="@/assets/images/type.svg" />
                  </button>
                </li>
              </FloatingMenu>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-if="currentTab == 'freezed'">
      <table class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th colspan="4">{{ $t("EducationalCategories.data") }}</th>
            <th colspan="2">{{ $t("students.data") }}</th>
            <th colspan="3">{{ $t("EducationalGroups.data") }}</th>
            <th rowspan="2">
              {{ $t("JoiningApplicationEducationals.freezedTo") }}
            </th>
            <th rowspan="2">
              {{ $t("JoiningApplicationEducationals.freezedReason") }}
            </th>
            <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
          </tr>
          <tr>
            <th>{{ $t("general.image") }}</th>
            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
            <th>{{ $t("EducationalJoiningApplications.code") }}</th>

            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>

            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
            <th>{{ $t("UserQualifications.joiningDateTime") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              joiningApplicationEducational, index
            ) in joiningApplicationEducationalsData"
            :key="index"
          >
            <td>{{ ++index + filterData.currentIndex }}</td>
            <td>
              <img
                class="item-img-table"
                :src="
                  fullPathFileFromServer(
                    joiningApplicationEducational.educationalCategoryInfoData
                      .educationalCategoryImagePath,
                    defaultImg
                  )
                "
                :onerror="`this.src='${defaultImg}'`"
              />
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalCategoryInfoData
                    .fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalCategoryInfoData
                    .educationalCategoryNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.userStudentInfoData
                    .fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational
                    .educationalJoiningApplicationInfoData.userStudentInfoData
                    .userNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalGroupJoining
                    .educationalGroupInfo.fullCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalGroupJoining
                    .educationalGroupInfo.educationalGroupNameCurrent
                )
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  joiningApplicationEducational.educationalGroupJoining
                    .placementDate,
                  joiningApplicationEducational.educationalGroupJoining
                    .placementTime
                )
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  joiningApplicationEducational.educationalGroupJoining
                    .freezedToDate,
                  joiningApplicationEducational.educationalGroupJoining
                    .freezedToTime
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  joiningApplicationEducational.educationalGroupJoining
                    .freezedNotes
                )
              }}
            </td>

            <td class="fmenu-item-container">
              <FloatingMenu>
                <li v-if="checkPrivilege(hasChangeStudentFreezeStatus())">
                  <button
                    @click="makeNotFreezed(joiningApplicationEducational)"
                    :title="$t('general.makeNotFreezed')"
                  >
                    <img src="@/assets/images/user-ok.svg" />
                  </button>
                </li>
              </FloatingMenu>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
  formateDateTimeLang,
} from "./../../../../utils/functions";
import {
  hasEducationalGroupStudentAdd,
  hasEducationalJoiningApplicationChangePostponedStatus,
  hasChangeStudentFreezeStatus,
} from "./../../../../utils/privilegeHelper";
import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  name: "JoiningApplicationEducationalsTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: [
    "joiningApplicationEducationalsData",
    "filterData",
    "defaultImg",
    "currentTab",
  ],
  computed: {},
  data() {
    return {};
  },
  methods: {
    setJoiningApplicationEducationalData(joiningApplicationEducational) {
      this.$emit(
        "setJoiningApplicationEducationalData",
        joiningApplicationEducational
      );
    },
    makeNotFreezed(joiningApplicationEducational) {
      this.$emit("makeNotFreezed", joiningApplicationEducational);
    },
    formateDateTimeLang,
    checkPrivilege,
    hasEducationalGroupStudentAdd,
    hasEducationalJoiningApplicationChangePostponedStatus,
    hasChangeStudentFreezeStatus,
    isDataExist,
    fullPathFileFromServer,
  },
};
</script>
